@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

// locla var
$schedule-hours-xs-dimension: 12px;
$schedule-hours-lg-dimension: 16px;
// style
.schedule-timeline-tics {
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
  padding-bottom: 0.5rem;

  // <ol>
  .columns {
    display: flex;
    flex-grow: 1;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  // <li>
  .col-item {
    min-width: $schedule-hours-xs-dimension;
    text-align: center;
    font-size: var(--font-size-xs);

    &.row-title {
      padding: 0;
    }

    time {
      display: block;
      line-height: 1;

      // &.this-day {
      //   color: var(--color-text-subtle-theme);
      // }
      // &.next-day {
      //   color: var(--color-text-theme);
      // }

      .tic-number {
        display: flex;
        justify-content: center;
        align-content: center;
        flex-direction: column;
        width: $schedule-hours-xs-dimension;
        color: var(--color-text-subtle-theme);
        background-color: transparent;

        &.active {
          color: var(--color-primary);
          font-weight: 700; /* bold */
        }
      }

      /* display dots for testing vertical alignment while developing */
      // &::after {
      //   content: "\2022";
      //   display: block;
      // }
    }
  }
  // responsive
  @media ($wx-lg-min) {
    .col-item {
      min-width: $schedule-hours-lg-dimension;

      time .tic-number {
        width: $schedule-hours-lg-dimension;
      }
    }
  }
  &.hide-tic-numbers {
    padding-bottom: 0;
    // <li>
    .col-item {
      time {
        line-height: 0;

        .tic-number {
          display: none;
        }
      }
    }
  }
}
