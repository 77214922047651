@import "@/styles/wx_breakpoints.scss";
@import '@/styles/variables.scss';
@import '~vuetify/src/styles/styles.sass';

// local var
$schedule_gutters: 4px;
$row_vertical_height: 56px;
$pattern_stroke_width: 3px;
$pattern_space: 6px;

/**
 * Form filters
 */
.filter-form {
  .col {
    display: flex;
    padding-top: 0;
    padding-bottom: 0;
  }
  fieldset.filters {
    display: flex;
    //flex-basis: content;
    flex-grow: 1;
    margin: 0 var(--grid-gutter) 0 0;
    padding: 0;
    border: 0 solid var(--color-border-theme);

    ::v-deep .v-input.v-input--dense {
      .v-input__control {
        .v-input__slot {
          border-radius: var(--border-radius-form-elements);

          // hide border-bottom
          &::before,
          &::after {
            display: none;
            border-color: transparent !important;
          }
        }
      }
    }
    .active-filter-counter {
      font-weight: 700; // bold
      .string {
        padding-left: 0.2rem;
        font-size: var(--font-size-sm);
        font-weight: 400;
        color: var(--color-text-subtle-theme);
      }
    }
  }

  &.single-row-filter {
    .col:last-child {
      justify-content: flex-end;
      align-items: stretch;
    }
    ::v-deep .v-input:not(.dropdown-nav) {
      margin-right: var(--grid-gutter);

      // prevent dropdowns from extending width according to selections
      width: 6rem;
      @media ($wx-lg-min) {
        width: 9rem;
      }
      @media ($wx-xl-min) {
        width: 11rem;
      }
    }
  }
  &.multi-row-filter {
    // align newShiftButton with page title on table
    margin-top: -4.8rem;
    ::v-deep .row:first-child {
      margin-bottom: -10px; // designer request to add space below button
    }

    .filters {
      &.row {
        margin-right: 0;
        .col {
          &:first-child {
            padding-left: 0;
          }
          &:last-child {
            padding-right: 0;
          }
        }
      }
    }
  }
}

.page-header {
  //transition: var(--smooth-transition);
  padding-bottom: var(--grid-gutter);

  &.filtered-chips {
    padding-bottom: 0;
  }
  // top button container
  .top-action {
    display: flex;
    flex-direction: row-reverse;
  }
  .filters {
    margin-bottom: 1rem;
  }
}

.page-body {
  min-width: $wx-min-width-tablet-vertical;
  overflow: visible;
  overflow-x: auto;

  .filtered-chips-container {
    height: auto;
    margin: 0 0 1rem;
    transition: var(--smooth-transition);

    // spacing between each group
    .filter-group {
      .filtered-chip {
        &:last-child {
          margin-right: var(--grid-gutter);
        }
      }
    }
  }
}

.page-body {
  background-color: transparent;
}
::v-deep .v-expansion-panels {
  .v-expansion-panel.week-schedule {
    background-color: transparent !important;
    transition: var(--smooth-transition);
  }
}

#production-lines {
  ::v-deep .shift-hours-colomns .row-title,
  .v-expansion-panels .v-expansion-panel-header .v-expansion-panel-header__icon > .v-icon {
    display: inline-block;
    width: 3rem;
  }
}

// Over-rule vuetify styles
.week-schedule.v-expansion-panel.v-expansion-panel--next-active,
.week-schedule-header.v-expansion-panel-header--mousedown,
.week-schedule-content > .v-expansion-panel-content__wrap {
  opacity: 1;
}

.week-schedule-header {
  &.v-expansion-panel-header,
  &.v-expansion-panel--active > .v-expansion-panel-header {
    min-height: $row_vertical_height !important;
  }
  &::before {
    background-color: transparent !important;
  }
  &.v-expansion-panel-header--mousedown {
    color: var(--color-primary);
  }
}

// Our mockups
.week-schedule.v-expansion-panel {
  &,
  &.v-expansion-panel--active,
  &.v-expansion-panel--mousedown {
    margin-top: ($schedule_gutters / 2);
    margin-bottom: ($schedule_gutters / 2);
  }
  .week-schedule-header {
    flex-direction: row-reverse;
    min-height: $row_vertical_height;
    padding: 0.2rem;
    border-radius: var(--border-radius-form-elements);
    background-color: var(--color-element-layer1);
    border: 1px solid var(--color-border-theme);

    .no-shift-cta {
      display: flex;
      justify-content: space-between;
      width: 20%;
      .v-chip {
        width: auto;
        height: auto;
        color: var(--color-text-disabled-theme);
        background-color: transparent;
      }
      // this style does NOT follow style guidelines
      .v-btn {
        &.v-btn--outlined:not(.disabled) {
          border-color: var(--color-contrast-theme);
        }
        &.disabled {
          display: none;
        }
      }
    }
    .line-name {
      padding-left: 0.4rem;
      font-weight: 700; /* bold */
    }

    @media ($wx-lg-min) {
      padding-left: 1rem;
      .line-name {
        padding-left: 1rem;
      }
    }
    @media ($wx-xl-min) {
      padding-left: 3.1rem;
      .line-name {
        padding-left: 3.1rem;
      }
    }
  }

  ::v-deep .week-schedule-content > div {
    padding-bottom: 0;
    padding-inline: 0;
  }
  .week-schedule-content {
    background-color: transparent;

    .row-title,
    .row-blocks-container {
      align-items: center;
      min-height: $row_vertical_height;
    }
    .row-title {
      color: var(--color-text-subtle-theme);
      text-transform: capitalize;
      background-color: var(--color-element-layer1);
      border: 1px solid var(--color-border-theme);
    }
    .row-blocks-container {
      background-color: transparent;
    }
  }
}

/**
 * Style days within .week-schedule-content
 */

.day-row {
  display: flex;
  align-items: center;
  position: relative;
  margin: $schedule_gutters 0;
  border-radius: var(--border-radius-form-elements);
}

// first columns are vertically aligned
.row-title,
::v-deep .col-item.row-title {
  clear: left;
}
::v-deep .col-item.row-title {
  clear: left;
  width: 22px;

  // responsive
  @media ($wx-lg-min) {
    width: 48px;
  }
  @media ($wx-xl-min) {
    width: 118px;
  }
}
.row-title {
  display: flex;
  justify-content: center;
  padding: 0.65rem 0;
  border-radius: var(--border-radius-form-elements);
  width: 22px;

  // responsive
  @media ($wx-lg-min) {
    width: 56px;
  }
  @media ($wx-xl-min) {
    width: 128px;
  }
}
.row-blocks-container {
  display: flex;
  width: 100%;
  margin-inline: 0;
  padding-left: 0;

  .block {
    flex-grow: 0;
    display: block;
    min-width: 0;
    width: auto;
    height: $row_vertical_height;
    background-color: var(--color-text-disabled-theme); // default

    &.v-btn {
      position: relative; // for positionning .downtime-subblock
      margin-inline: ($schedule_gutters / 2);
      padding: 0.5rem 0.25rem 0.5rem 0.75rem;
      border-radius: var(--border-radius-form-elements);

      // Vertical cutting lines
      &.truncated-right {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0;
        border-right: 1px dashed var(--color-text-subtle-theme);
      }
      &.truncated-left {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        border-left: 1px dashed var(--color-text-subtle-theme);
      }

      ::v-deep .v-btn__content {
        flex-direction: column;
        align-items: flex-start;
        // to display .downtime-subblock
        position: unset;
        overflow: hidden;
      }

      .string,
      .digits {
        display: inline-block;
        z-index: 2;
        width: 100%;
      }
      .string {
        font-weight: 700;
      }
      .digits {
        margin-top: 0.3rem;
      }

      // this pattern
      .downtime-subblock {
        z-index: 0;
        position: absolute;
        top: 0;
        left: 0;
        display: block;
        min-width: 1px;
        height: 100%;
        background-color: var(--color-subtle-container-theme);
        background-image: repeating-linear-gradient(
          -45deg,
          transparent,
          transparent $pattern_space,
          var(--color-grid-background-theme) $pattern_space,
          var(--color-grid-background-theme) ($pattern_stroke_width + $pattern_space)
        );
      }
    }

    /**
     * !block.workShiftId = placeholders required
     * to vertically align blocks with hour ticks
     */
    &.placeholder {
      min-width: 1px;
      margin: 0;
      padding: 0;
      background-color: transparent;
    }
  }

  // responsive
  @media ($wx-sm-min) {
    padding-left: 0;
  }
  @media ($wx-lg-min) {
    width: calc(100% - 3rem);
  }
}

/** -------------------------
 * Unsupported page size alert
 * @media XS
 */
.v-alert {
  &.alert-unsupported-dimension {
    // manage background
    &.v-alert--text:before {
      //background-color: transparent;
    }
    ::v-deep.v-icon {
      color: var(--color-text-subtle-theme);
    }
    // alert contents
    .alert-title,
    .alert-options > .heading {
      margin-bottom: 1rem;
    }

    .alert-title {
      line-height: 1.5; // align verticaly with v-icon
    }
    .alert-options {
      margin-top: 1.5rem;
      padding-top: 1.5rem;
      border-top: 1px solid var(--color-border-theme);

      li {
        display: flex;
        align-items: flex-start;

        .v-icon {
          margin-right: 1rem;
        }
      }
    }
  }
  &.nodata {
    margin-top: 5vh;
  }
}

/**
 * Random pigments to differentiate contents
 * `https://worximity.atlassian.net/l/c/0g0cKHEp`
 */
@for $i from 0 through 8 {
  .block.option-pigment#{$i} {
    background-color: var(--wxData_bgColor#{$i});
  }
}
